@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @apply text-[42px] sm:text-5xl md:text-[56px] lg:text-7xl xl:text-8xl font-bold text-white leading-tight font-heading;
}
h2 {
  @apply text-4xl sm:text-[42px] md:text-[46px] font-bold text-white leading-tight font-heading;
}
h3 {
  @apply text-[30px] md:text-4xl font-bold text-white leading-tight font-heading;
}
h4 {
  @apply text-[40px] md:text-5xl font-normal text-white leading-tight;
}

h5 {
  @apply text-base font-normal text-white leading-tight;
}

p {
  @apply text-sm font-normal text-white;
}

.p-default {
  @apply px-4 md:px-8 lg:px-16;
}

.py-default {
  @apply py-16;
}

.btn-white {
  @apply bg-white rounded-[40px] mx-auto px-12 py-3 text-black text-base mt-8 font-bold;
}

.btn-white:hover {
  @apply text-white;
  background: linear-gradient(90deg, #00ffd4, #0093ff);
}

.gradient-border {
  background: linear-gradient(#1d1d1d, #1d1d1d) padding-box,
    linear-gradient(90deg, #00ffd4, #0093ff) border-box;
  border: 2px solid transparent;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.num-lg {
  @apply text-7xl font-bold;
  background: -webkit-linear-gradient(90deg, #00ffd4, #0093ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-nav a {
  @apply text-white;
}

.header-nav a:hover {
  @apply text-[#00ffd4];
}

.header-nav-burger a {
  @apply text-[30px] font-bold text-white leading-tight font-heading;
}

.header-nav-burger a:hover {
  @apply text-[#00ffd4];
}

.userform-border {
  border: 2px solid #00ffd4;
  @apply bg-transparent;
}

.userform input {
  @apply rounded-xl px-4 py-3 text-xs mt-2;
  background: #ffffff;
  opacity: 0.5;
  color: #101010;
  opacity: 0.6;
}

.userform label {
  @apply text-white text-xs;
}

#footer a {
  @apply text-sm text-white/70;
}

.error-span {
  @apply text-red-500 font-bold text-xs mt-2;
}

.success-span {
  @apply text-green-600 font-bold text-xs;
}